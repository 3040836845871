import Navbar 				from "./components/Navbar";

import Store 				from "./pages/Store";
import ProductDetail 		from './pages/ProductDetail';

// import Pricing 			from "./pages/Pricing";
import Home 				from "./pages/Home";
// import About 				from "./pages/About";
import MyCourses 			from "./pages/MyCourses";
import Lessons 				from "./pages/Lessons";
import Login 				from "./pages/Login";
// import Test 				from "./pages/Test";
import Success 				from "./pages/Success";
import Cancel 				from "./pages/Cancel";
import SignUp 				from "./pages/SignUp";

import React from 'react';
import { Route, Routes } 	from "react-router-dom"

import { AuthProvider } 	from './context/AuthContext';


function App() {

	// console.log(process.env.REACT_APP_PUBLIC_API_KEY);
	// console.log(process.env.local.NEXT_PUBLIC_API_KEY);

	//  const location = useLocation();
  	// const isHomePage = location.pathname === '/'; // Controleer of de gebruiker op de homepagina is


  	return (
		<>
			<AuthProvider>

				{/* <Navbar />
			
				<div className="container">
					<Routes>
						<Route path="/" 			element={<Home />} />
						<Route path="/store" 		element={<Store />} />
						<Route path="/product/:id" 	element={<ProductDetail />} />
						<Route path="/Lessons" 		element={<Lessons />} />
						<Route path="/MyCourses" 	element={<MyCourses />} />
						<Route path="/login" 		element={<Login />} />
						<Route path="/cancel" 		element={<Cancel />} />
						<Route path="/success" 		element={<Success />} />
						<Route path="/signup" 		element={<SignUp />} />
					</Routes>
				</div> */}

				<h1> COMING SOON </h1>
			
			</AuthProvider>
		</>
  	)
}

export default App
