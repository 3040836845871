import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../utils/firebase";
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";

// Auth context maken
const AuthContext = createContext();

// Custom hook om de context te gebruiken
export const useAuth = () => useContext(AuthContext);



// AuthProvider component
export const AuthProvider = ({ children }) => {

	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Persistentie instellen
		setPersistence(auth, browserLocalPersistence)
			.then(() => {
				// Luisteren naar auth state changes
				const unsubscribe = onAuthStateChanged(auth, (user) => {
					if (user) {
						setUser(user);
					} else {
						setUser(null);
					}
					setLoading(false);
				});

				return () => unsubscribe();
			})
			.catch((error) => {
				console.error("Error setting auth persistence:", error);
			});
	}, []);

	return (
		<AuthContext.Provider value={{ user, loading }}>
			{!loading && children}
		</AuthContext.Provider>
	);
};
