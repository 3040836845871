import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./styles.css"
import { BrowserRouter } from "react-router-dom"



const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	// - React StrictMode roept alles dubbel aan (okt 24)
	// <React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	// </React.StrictMode>
)
