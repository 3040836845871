// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

	apiKey: 			process.env.REACT_APP_PUBLIC_API_KEY,
	authDomain: 		process.env.REACT_APP_PUBLIC_AUTH_DOMAIN,
	projectId: 			process.env.REACT_APP_PUBLIC_PROJECT_ID,
	storageBucket: 		process.env.REACT_APP_PUBLIC_STORAGE_BUCKET,
	messagingSenderId: 	process.env.REACT_APP_PUBLIC_MESSAGE_SENDER_ID,
	appId: 				process.env.REACT_APP_PUBLIC_APP_ID,
	measurementId: 		process.env.REACT_APP_PUBLIC_MEASUREMENT_ID
};

// Initialize Firebase
export const app = initializeApp(
	firebaseConfig,
	{
  		region: 'us-central1' // Vervang 'your-region' door de gewenste regio
	}
);

// const analytics = getAnalytics(app);


export const auth = getAuth();


// Initialiseer Firestore database
export const db = getFirestore(app);

// [ ] nog testen 								(zie https://firebase.google.com/docs/functions/callable?gen=1st#web-modular-api_4)
// export const functions = getFunctions();

export const storage = getStorage(app);
